import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import IntroWithCarouselSection from '../sections/IntroWithCarouselSection';
import TextImageSection from '../sections/TextImageSection';

export const SolutionPageTemplate = ({
  title, description, images, productFamilies
}) => (
  <div className="flex-grow-1">
    <div className="navHeight" />
    <IntroWithCarouselSection title={ title } description={ description } images={ images } />
    {productFamilies.length > 0 && (
      <div className="bg-secondary py-5">
        {productFamilies.map((productFamilie, i) => (
          <TextImageSection
            title={ productFamilie.title }
            description={ productFamilie.description }
            textDirection={ i % 2 === 0 ? 'right' : 'left' }
            hasAction
            actionText={ productFamilie.actionTitle }
            actionRoute={ productFamilie.route }
            image={ productFamilie.image }
            imageStyle={{ height: '450px' }}
            sectionClassName="bg-secondary"
            key={ i }
          />
        ))}
      </div>
    )}
  </div>
);

SolutionPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  productFamilies: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.objectOf(PropTypes.object),
    actionTitle: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
  })).isRequired
};

const SolutionPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;

  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <SolutionPageTemplate
        title={ frontmatter.title }
        description={ frontmatter.description }
        images={ frontmatter.images }
        productFamilies={ frontmatter.productFamilies }
      />
    </Layout>
  );
};

SolutionPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export default SolutionPage;

export const pageQuery = graphql`
  query SolutionPageByID($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { language: { eq: $language }} ) {
      id
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        images {
          imageObject {
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            bannerText
          }
        }
        productFamilies {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          actionTitle
          route
        }
      }
    }
  }
`;