import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ControlledCarousel from '../components/ControlledCarousel';

const IntroWithCarouselSection = ({ title, description, images }) => {
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(0);
  const handleSelect = (selectedIndex) => {
    setSelectedCarouselItem(selectedIndex);
  };

  const items = images.map((item) => ({ image: item.imageObject.image, bannerText: item.imageObject.bannerText }));
  return (
    <Container className="py-5 my-0 bg-white" fluid>
      <Container>
        <Row className="justify-content-between" noGutters>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 3 } xl={ 3 } className="line-decoration line-decoration-dark pt-1 my-3">
            <h3 className="text-break">{title}</h3>
            <p>{description}</p>
          </Col>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 8 } className="my-3">
            <ControlledCarousel items={ items } activeIndex={ selectedCarouselItem } selectHandler={ handleSelect } showPreviews={ false } />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

IntroWithCarouselSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default IntroWithCarouselSection;